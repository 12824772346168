import {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    postNoAuthRequest,
    getNoAuthRequest,
    getNoAuthRequestKw,
    downloadRequest
} from "@/libs/axios";


// 查询准考证
export const getTicket = params => {
    return getRequest("/yethan/ticketBank/getTicket", params);
};

// 获取准考证模板详情
export const getPrintTemplate = params => {
    return getRequest("/yethan/print/printTemplate/" + params);
};


// 获取准考证库详情
export function getZkzInfo(sid,sfkwks) {
    return getRequest((sfkwks?"/yethan/ticketBankRecord/kwks/":"/yethan/ticketBankRecord/") + sid);
}
// 查准考证详情以及所有科目
export function getZkzSubject(params) {
    return getRequest('/yethan/ticketBank/getTicket', params);
}

// 准考证查询信息提交
export function zkzSearch(params) {
    return getNoAuthRequest('/yethan/ticketBank/getTicketByCode', params);
}
export function zkzSearchKw(params) {
    return getNoAuthRequestKw('/yethan/public/queryExaminee', params);
}

// 下载图片
export function downLoad(params,params2) {
    return getNoAuthRequest('/yethan/file/downloadPub/base64/'+ params+'/'+params2);
}
// 管理员查询准考证
export function adminGetZkz(params) {
    return getNoAuthRequest('/yethan/ticketBank/getTicketTest', params);
}
//下载准考证（登录后）
export function getTicketPDF(params) {
  return downloadRequest("/yethan/ticketBank/getTicketPDF", params);
}

//下载准考证（不登录）
export function getTicketPDFNoLogin(params) {
    return downloadRequest("/yethan/ticketBank/getTicketPDFByCode", params);
  }

  //二维码扫描读取准考证
export function getCodeZkz(params) {
    return getNoAuthRequest('/yethan/ticketBank/getTicketQrcode', params);
}
export function getCodeZkzKw(params) {
    return getNoAuthRequestKw('/yethan/public/queryExamineeQrcode', params);
}
// 准考证查二维码
export function getCodeByZkz(params) {
    return getRequest('/yethan/ticketBank/getQrcode', params);
}
