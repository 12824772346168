<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<script>
import $ from "jquery";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import Swal from "sweetalert2";
import { resetCode } from "@/libs/common";
import { getUserExamList, getMbInfo, getticket } from "@/api/examination";
import { getPrintTemplate, getZkzInfo } from "@/api/grade/grade";
import { format } from "@/libs/common";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  computed: {},

  data() {
    return {
      title: "打印准考证",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "打印准考证",
          active: true,
        },
      ],
      uesrInfo: {},
      Countdown: 0,
      showModal: false,
      zkzsmInfo: "",
      zkzList: [],
      zkzIndex: 0,
      timer: null,
      time: "", //年份
      timeList: [],
      zkzgzcnhList: [], //准考证承诺书号数组
      zkzgzcnhIndex: 0,
    };
  },
  watch: {
    zkzsmInfo: {
      handler(data) {
        let text = this.zkzsmInfo.mbnr;
        text = text.replace(/{XM}/g, this.uesrInfo.xm);
        text = text.replace(/{ZJLX}/g, this.uesrInfo.sfzjlxmc);
        text = text.replace(/{SFZH}/g, this.uesrInfo.sfzjh);
        text = text.replace(/{RQ}/g, format(new Date(), "yyyy年MM月dd日"));
        this.zkzsmInfo.mbnr = text;
      },
    },
    showModal: function () {
      if (this.showModal == false) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    openModal(index) {
      let data1 = this.zkzList[index];
      let routeData = this.$router.resolve({
        path: "../ticketSearch",
          query: {
              mbh: data1.mbh,
              sid: data1.sid,
              islogin: "true",
          },
      });
      window.open(routeData.href, '_blank');
      return;

      var _this = this;
      _this.zkzIndex = index;
      _this.showModal = true;
      let data = _this.zkzList[index];
      _this.zkzsmInfo = "";
      getZkzInfo(data.sid).then((res) => {
        if (res.status) {
          if (res.data.zkzgzcnh) {
            this.zkzgzcnhList = res.data.zkzgzcnh.split(",");
            this.zkzgzcnhIndex = 0;
            getMbInfo(this.zkzgzcnhList[0]).then((res2) => {
              if (res.status) {
                _this.zkzsmInfo = res2.data || "";
                _this.Countdown = 3;
                this.zkzgzcnhIndex++;
                _this.timer = setInterval(function () {
                  if (_this.Countdown > 0) {
                    _this.Countdown -= 1;
                  } else {
                    clearInterval(_this.timer);
                    // data.hasTimer = false;
                  }
                }, 1000);
              }
            });
          }
        }
      });
    },
    agreeCommit() {
      let _this = this;

      if (this.zkzgzcnhList[this.zkzgzcnhIndex]) {
        getMbInfo(this.zkzgzcnhList[this.zkzgzcnhIndex]).then((res) => {
          if (res.status) {
            _this.zkzsmInfo = res.data || "";
            _this.Countdown = 3;
            this.zkzgzcnhIndex++;
            _this.timer = setInterval(function () {
              if (_this.Countdown > 0) {
                _this.Countdown -= 1;
              } else {
                clearInterval(_this.timer);
                // data.hasTimer = false;
              }
            }, 1000);
          }
        });
      } else {
        let data = this.zkzList[this.zkzIndex];
        this.$router.push({
          path: "./printCard",
          query: {
            mbh: data.mbh,
            sid: data.sid,
            // zkzgzcnh: data.zkzgzcnh,
          },
        });
      }
    },
    downLoadZkz() {
      let data = this.zkzList[this.zkzIndex];
      let a = document.createElement("a");
      let url = this.FILE_URL + "";
      a.setAttribute("href", url);
      a.setAttribute("download", data.sid);

      document.body.appendChild(a);
      a.click();
    },
    getticketList(ksnf) {
      getticket({ ksnf }).then((res) => {
        if (res.status) {
          let newTime = new Date().getTime();
          let list = res.data || [];
          list.forEach((item) => {
            item.hasTimer = true;
          });
          list.forEach((v) => {
            if (
              v.kfrq &&
              new Date(v.kfrq.replace(/-/g, "/")).getTime() < newTime
            ) {
              if (
                v.jsrq &&
                new Date(v.jsrq.replace(/-/g, "/")).getTime() > newTime
              ) {
                v.canPrint = true;
              } else if (!v.jsrq) {
                v.canPrint = true;
              }
            }
          });
          this.zkzList = list.filter((v) => {
            return v.sfyxdy != 0 && v.canPrint;
          });
        }
      });
    },
    change() {
      this.getticketList(this.time);
    },
  },
  created() {
    this.uesrInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let year = new Date().getFullYear();
    this.timeList = [year, year - 1, year - 2, year - 3, year - 4];
    this.time = year;
    this.getticketList(year);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2">打印准考证项目列表</span>
              <el-select
                v-model="time"
                placeholder="请选择年份"
                style="min-width: 6%"
                size="small"
                class="m-2 w-10"
                @change="change"
              >
                <el-option
                  v-for="item in timeList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </h4>
            <div class="table-responsive border mt-3">
              <table
                class="table table-has-bg light-table table-hover table-bordered"
              >
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%">序号</th>
                    <th>考试名称</th>
                    <!-- <th style="width: 300px">准考证名称</th> -->
                    <th style="width: 300px">打印日期</th>
                    <th style="width: 150px">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in zkzList" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <a href="" class="blue-font line2">{{ item.ksmc }}</a>
                    </td>
                    <!-- <td>{{ item.zkzmc }}</td> -->
                    <td>
                      {{ item.kfrq || "--" }}<span class="m-1">至</span
                      >{{ item.jsrq || "--" }}
                    </td>

                    <td>
                      <b-button
                        variant="info"
                        v-if="item.canPrint"
                        class="h30 w-md"
                        @click="openModal(index)"
                        ><i class="iconfont mr-2 icon-printer"></i
                        >打印准考证</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-if="zkzList.length == 0"
                class="d-flex justify-content-center mt-3"
                style="min-height: 200px"
              >
                暂无可打印准考证
              </div>
            </div>

            <div class="mt-2 mb-3">
              <div class="text-danger">◆ 打印准考证必读！<br /></div>
              <div class="indent-div">
                一、须在规定的准考证打印时间内完成打印。
              </div>
              <div class="indent-div">
                二、准考证可立即联机打印或者下载后再打印，下载保存的准考证文件格式为PDF。
              </div>
              <div class="indent-div">
                三、打印机选择黑白或彩色均可，但须字迹清晰，照片可辨认。
              </div>
              <div class="indent-div">
                四、如遇到网络繁忙或网速太慢而导致准考证无法正常打印时，请对网页进行刷新或者避开网络高峰期，选择凌晨时段进行打印。
              </div>

              <div class="text-danger mt-3">◆ 打印时务必注意：<br /></div>
              <div class="indent-div">
                一、核对姓名、身份证号、照片、报考信息等是否显示正确。
              </div>
              <div class="indent-div">
                二、核对报考类别和考试科目时间是否对应。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 准考证打印弹窗协议 -->
    <b-modal
      v-model="showModal"
      v-if="zkzList.length != 0"
      id="commitModal"
      size="lg"
      title="四川人事考试考生诚信承诺书"
      title-class="font-18 promise-title"
      hide-footer
      centered
    >
      <div
        class="mt-2 agree-text"
        v-if="zkzsmInfo.mbnr"
        v-html="zkzsmInfo.mbnr"
      ></div>
      <div class="mt-2 agree-text" v-if="!zkzsmInfo.mbnr">
        本人郑重承诺：<br />
        <div class="indent-div">
          报名时我所填写的姓名、性别、出生年月、身份证号、学历、专业、毕业时间、工作单位、联系电话等基本信息真实可靠，所选考试科目、级别、职位等相关信息准确无误，档案号正确唯一（指参加滚动考试的老考生），提供的学历证书、资格证书等有关证件真实有效。如因填报的各项信息有误影响考试及考试结果的使用，由本人负责。
        </div>
        <div class="indent-div">
          考试时自觉遵守《专业技术人员资格考试违纪违规行为处理规定》（人社部令第31号）、《公务员录用违规违纪行为处理办法》（中组发〔2021〕12号）、《事业单位公开招聘违纪违规行为处理规定》（人社部令第35号）、《四川省考试考务工作细则》和《四川省人事考试违规违纪行为处理办法》中的有关规定，自觉遵守考场纪律和考场规则，服从考务工作人员和监考老师安排。
        </div>
        <div class="indent-div">
          考试时凭本人准考证和正式有效居民身份证原件（不含过期身份证、旧版临时身份证、学生证和驾驶证等证件）参加考试。
        </div>
        <div class="indent-div">
          本人已周知《专业技术人员资格考试违纪违规行为处理规定》（人社部令第31号）、《公务员录用违规违纪行为处理办法》（中组发〔2021〕12号）、《事业单位公开招聘违纪违规行为处理规定》（人社部令第35号），认同并遵守试卷代码异常及雷同试卷认定和处理的相关规定，承担相关责任。
        </div>
        <div class="indent-div">
          以上承诺本人将严格遵守，如有违反，本人愿意承担一切后果，并自愿接受有关部门的处理。
        </div>
      </div>

      <div class="text-center modal-btn" v-if="Countdown != 0">
        <button
          type="button"
          class="btn btn-secondary w-lg"
          style="cursor: no-drop"
        >
          我已知晓并同意（剩余 {{ Countdown }} s）
        </button>
      </div>
      <div class="text-center modal-btn" v-if="Countdown == 0">
        <button type="button" class="btn btn-info w-lg" @click="agreeCommit">
          我已知晓并同意
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
